.footer {
    height: 267px;
    background: linear-gradient(231deg, #465165 0%, #36455C 100%);
    // padding: 0 76px;

    padding: 0 0 0 76px;
    .con {
        border-bottom: 2px solid #FFFFFF;
        padding-bottom: 42px;
        height: 189px;
        &>div {
            display: inline-block;
            vertical-align: middle;
        }

        .nav {
            font-size: 24px;
            font-family: HYQiHei-50S, HYQiHei;
            font-weight: normal;
            color: #FFFFFF;
            float: left;
            margin-top: 59px;
            div {
                display: inline-block;
                margin-right: 62px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    color: rgba(255, 255, 255, 0.4);
                }
            }
        }

        .info {
            font-size: 18px;
            font-family: HYQiHei-50S, HYQiHei;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.4);
            margin-top: 60px;
            &1 {
                margin-left: 391px;
            }
            &2 {
                margin-left: 66px;
            }
            .contact {
                margin-bottom: 19px;
            }
            span {
                color: #14C896;
                margin-right: 22px;
            }

            .bottom {
                margin-left: 94px;
                margin-top: 19px;
            }
        }
    }

    .copyright {
        font-size: 16px;
        font-family: HYQiHei-50S, HYQiHei;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 22px;
        .company{
            margin-left: 89px;
        }
        a {
            text-decoration: none;
            &:link,&:visited,&:active {
                color: rgba(255, 255, 255, 0.7);
            }
            &:hover {
                color: blue;
            }
        }
    }

    .right-icon {
      
       float: right;
        span {
            display: inline-block;
            background-size: cover;
            vertical-align: top;
            cursor: pointer;
        }

        .link {
            width: 32px;
            height: 30px;
            background-image: url(../../images/link.webp);
        }

        .weixin {
            width: 37px;
            height: 34px;
            background-image: url(../../images/weixin.webp);
            margin-left: 22px;
            margin-right: 15px;
        }
    }
    .police {
        display: inline-block;
        vertical-align: middle;
        width: 36px;
        height: 40px;
        background-size: cover;
        transform: scale(0.6) translateY(-5px);
        background-image: url(../../images/police.png);
    }
}
.footerpreview {
    .ant-image-preview-img {
        width: 300px;
    }
}