* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #020915;
}
video {
  object-fit: cover;
}