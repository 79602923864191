.backtop {
    width: 54px;
    height: 54px;
    background-color: #485367;
    border-radius: 50%;
    position: fixed;
    right: 25px;
    bottom: 45px;
    cursor: pointer;
    z-index: 9;
    transform: scale(1.2);
    &::after {
        content: '';
        display: inline-block;
        height: 28px;
        width: 18px;
        background-image: url(../../../images/top-icon.webp);
        background-size: 18px 28px;
        position: absolute;
        left: 17px;
        top: 14px;
    }
}