.header {
    padding: 0 100px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
    &>div {
        display: inline-block;
        vertical-align: middle;
    }
    .logo {
        width: 235px;
        height: 35px;
        background: url(../../images/logo.png);
        background-repeat: no-repeat;
        background-size: 235px 35px;
        margin-top: 54px;
    }

    .nav {
        margin-top: 63px;
        float: right;
        font-size: 20px;
        font-family: HYQiHei-40S, HYQiHei;
        font-weight: normal;
        color: #FFFFFF;
        &>div {
            display: inline-block;
            vertical-align: middle;
            margin-left: 50px;
            cursor: pointer;
            &.active {
                color: #14C896;
            }
        }
    }
    &.hide {
        display: none;
        transition: ease-in-out 1;
    }
}