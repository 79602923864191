.gray {
    color: rgba(255,255,255, 0.8);
}
.lookmore {
    cursor: pointer;
    font-size: 24px;
    font-family: HYQiHei-50S, HYQiHei;
    font-weight: normal;
    color: #FFFFFF;
    .arrowright {
        height: 19px;
        width: 10px;
        display :inline-block;
        position: relative;
        &:first-child {
            margin-left: 8px;
        }
        &::after {
            content: "";
            height: 12px;
            width: 12px;
            top: 5px;
            border-width: 3px 3px 0 0;
            border-color: #14C896;
            border-style: solid;
            transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
            position: absolute;
          }
    }
}
.ant-modal .ant-modal-content  {
    background-color: transparent !important;
    padding: 0 !important;
    .ant-modal-close {
        top: 10px !important;
        right: 10px !important;
        color: white !important;
    }
}
.ant-carousel .slick-dots li {
    width: auto;
    button {
        background: rgba(255,255,255,0.4)  !important; 
        width: 14px  !important;
        height: 14px  !important;
        border-radius: 7px  !important;
        margin-right: 33px  !important;
    }
    &.slick-active {
        width: auto;
        button {
            width: 70px !important;
            background: #40EDA4 !important;
        }
    }
}

.el-carousel__indicators--outside  {
    button {
        background: rgba(255,255,255,0.4); 
        width: 14px;
        height: 14px;
        border-radius: 7px;
        margin-right: 33px;
    }
    .is-active {
        button {
            width: 70px;
            background: #40EDA4;
        }
    }
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-modal-root .ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.75) !important;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-float-btn-default .ant-float-btn-body {
    background-color: #485367;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-float-btn-circle{
    width: 50px;
    height: 50px;
    .ant-float-btn-content {
        display: none;
    }
}
.el-carousel {
    overflow-x:initial !important;
}
.arrordiv {
    .carouselarror {
        display: none;
    }
    &:hover {
        .carouselarror {
            display: block;
        }
    }
}
